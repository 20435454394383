import React, { useState, useEffect } from 'react';
import { useTool } from '../contexts/ToolContext';

const Brush = ({ canvasRef }) => {
  const { selectedTool } = useTool();
  const [isDrawing, setIsDrawing] = useState(false);

  // Function to start drawing on the canvas
  const startDrawing = (event) => {
    if (selectedTool === 'brush') { // Only activate if brush tool is selected
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.beginPath(); // Start a new path
      ctx.moveTo(event.clientX - canvas.offsetLeft, event.clientY - canvas.offsetTop); // Move to the starting point
      setIsDrawing(true);
    }
  };

  // Function to draw on the canvas as the mouse moves
  const draw = (event) => {
    if (!isDrawing || selectedTool !== 'brush') return; // Draw only if drawing is active and brush tool is selected
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineTo(event.clientX - canvas.offsetLeft, event.clientY - canvas.offsetTop); // Draw a line to the current position
    ctx.strokeStyle = 'black'; // Brush color
    ctx.lineWidth = 5; // Brush size
    ctx.stroke(); // Render the line
  };

  // Function to stop drawing on the canvas
  const stopDrawing = () => {
    if (isDrawing) {
      setIsDrawing(false);
    }
  };

  // Attach event listeners for mouse events
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Add event listeners to handle drawing
    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseleave', stopDrawing); // Handle the case when the mouse leaves the canvas

    // Cleanup event listeners on component unmount
    return () => {
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mouseleave', stopDrawing);
    };
  }, [isDrawing, selectedTool]);

  return null; // No UI needed for this component
};

export default Brush;
