import React, { createContext, useState, useContext } from 'react';

const ToolContext = createContext();

// Create a provider component
export const ToolProvider = ({ children }) => {
    const [selectedTool, setSelectedTool] = useState(null);
  
    return (
      <ToolContext.Provider value={{ selectedTool, setSelectedTool }}>
        {children}
      </ToolContext.Provider>
    );
  };
  
  // Custom hook to use the ToolContext
  export const useTool = () => useContext(ToolContext);