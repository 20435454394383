import React from 'react';
import styles from '../styles/Toolbar.module.css';
import { useTool } from '../contexts/ToolContext.js';
import { MdPanTool } from "react-icons/md";
import { FaPaintBrush } from "react-icons/fa";
import { FaEraser } from "react-icons/fa";

function Toolbar() {

    const { selectedTool, setSelectedTool } = useTool();

    const handleToolClick = (tool) => {
        // Avoid unnecessary re-rendering or clearing of the canvas when changing tools
        if (selectedTool !== tool) {
            setSelectedTool(tool);
            console.log(`Tool selected: ${tool}`);
        }
    };


    return (
        <div className={styles.toolbarContainer}>
            <div className={styles.toolbar}>
                {/* <button onClick={() => handleToolClick('select')} style={{height: 40, width: 40}}>👆</button> */}
                <button onClick={() => handleToolClick('drag')} style={{height: 40, width: 40}}><MdPanTool /></button>
                <button onClick={() => handleToolClick('brush')} style={{height: 40, width: 40}}><FaPaintBrush /></button>
                <button onClick={() => handleToolClick('eraser')} style={{height: 40, width: 40}}><FaEraser /></button>
                {/* <button onClick={() => handleToolClick('bucket')} style={{height: 40, width: 40}}>🪣</button> */}
            </div>
        </div>
    )
}

export default Toolbar;