import React, { useState, useEffect } from 'react';
import { useTool } from '../contexts/ToolContext';

const Eraser = ({ canvasRef }) => {
  const { selectedTool } = useTool();
  const [isErasing, setIsErasing] = useState(false);

  // Function to start drawing on the canvas
  const startErasing = (event) => {
    if (selectedTool === 'eraser') { // Only activate if brush tool is selected
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.beginPath(); // Start a new path
      ctx.moveTo(event.clientX - canvas.offsetLeft, event.clientY - canvas.offsetTop); // Move to the starting point
      setIsErasing(true);
    }
  };

  // Function to draw on the canvas as the mouse moves
  const erase = (event) => {
    if (!isErasing || selectedTool !== 'eraser') return; // Draw only if drawing is active and brush tool is selected
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineTo(event.clientX - canvas.offsetLeft, event.clientY - canvas.offsetTop); // Draw a line to the current position
    ctx.strokeStyle = 'white'; // Brush color
    ctx.lineWidth = 5; // Brush size
    ctx.stroke(); // Render the line
  };

  // Function to stop drawing on the canvas
  const stopErasing = () => {
    if (isErasing) {
      setIsErasing(false);
    }
  };

  // Attach event listeners for mouse events
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Add event listeners to handle drawing
    canvas.addEventListener('mousedown', startErasing);
    canvas.addEventListener('mousemove', erase);
    canvas.addEventListener('mouseup', stopErasing);
    canvas.addEventListener('mouseleave', stopErasing); // Handle the case when the mouse leaves the canvas

    // Cleanup event listeners on component unmount
    return () => {
      canvas.removeEventListener('mousedown', startErasing);
      canvas.removeEventListener('mousemove', erase);
      canvas.removeEventListener('mouseup', stopErasing);
      canvas.removeEventListener('mouseleave', stopErasing);
    };
  }, [isErasing, selectedTool]);

  return null; // No UI needed for this component
};

export default Eraser;
