import React, { useState, useEffect, useRef } from 'react';
import { useTool } from '../contexts/ToolContext.js';

function Drag({ canvasRef }) {
  const { selectedTool } = useTool();
  const isDraggingRef = useRef(false); // Ref to track dragging state
  const selectedToolRef = useRef(selectedTool); // Ref to track the selected tool
  const savedImageDataRef = useRef(null);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    selectedToolRef.current = selectedTool; // Update the tool ref whenever the tool changes
  }, [selectedTool]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');

    const handleMouseDown = (e) => {
      if (selectedToolRef.current !== "drag") return;

      isDraggingRef.current = true; // Update dragging state
      savedImageDataRef.current = ctx.getImageData(0, 0, canvas.width, canvas.height);
      setStartPos({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e) => {
      if (selectedToolRef.current !== "drag" || !isDraggingRef.current) return;

      const offsetX = e.clientX - startPos.x;
      const offsetY = e.clientY - startPos.y;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.putImageData(savedImageDataRef.current, offsetX, offsetY);
    };

    const handleMouseUp = () => {
      if (selectedToolRef.current === "drag") {
        isDraggingRef.current = false;
      }
    };

    // Attach event listeners
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);

    // Cleanup listeners on unmount
    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
    };
  }, [canvasRef, startPos]);

  return null; // No canvas rendered; event handling is managed here
}

export default Drag;
