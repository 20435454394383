import { useRef, useEffect } from 'react';
import styles from '../styles/Whiteboard.module.css';
import Brush from './Brush';
import Drag from './Drag';
import Eraser from './Eraser';

function Whiteboard() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Set the canvas dimensions
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }, []);

  return (
    <div className={styles.whiteboardContainer}>
      <canvas ref={canvasRef} className={styles.whiteboard}></canvas>
      <Brush canvasRef={canvasRef} />
      <Drag canvasRef={canvasRef} />
      <Eraser canvasRef={canvasRef} />
    </div>
  );
}

export default Whiteboard;
